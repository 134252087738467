import React, { useState } from 'react';

import Layout from '../../../components/layout';
import LicensedIn from '../../../components/licensed-in';
import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const faq = useStaticQuery(graphql`
    query MyQuery {
      allContentfulGlossary {
        edges {
          node {
            id
            term
            children {
              id
            }
            childContentfulGlossaryDescriptionRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
    }
  `);

  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  console.log('faq', faq);
  return (
    <Layout activePage='resources' {...props}>
      <LicensedIn {...props} />
    </Layout>
  );
};
